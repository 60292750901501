import React, { useState, useRef, useEffect, useContext } from "react";
import "./index.css";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import { generateText, fetchData, uploadFile } from "../services/api.services";
import { ClipLoader } from "react-spinners";
import { FaArrowCircleDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import User from "./Usermenu";
// import { AuthContext } from "../services/AuthContext"; // Adjust the path as needed
import axios from "axios";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import {
  S3_BUCKET,
  REGION,
  ACCESS_KEY,
  SECRET_ACCESS_KEY,
} from "../AwsConfig/awsConfig";

import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";

const RightSide = ({ showFileInput, clearHistory, evaluationType }) => {
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const chatBoxRef = useRef(null);
  const navigate = useNavigate();
  // const { currentUser, initialLetter } = useContext(AuthContext);
  const [evaluationData, setEvaluationData] = useState([]);
  const [loadingEvaluationData, setLoadingEvaluationData] = useState(true);
  const [error, setError] = useState(null);
  const [expandedContent, setExpandedContent] = useState({});
  const [modalTitle, setModalTitle] = useState(""); // Add state for the column name
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [objectURL, setObjectURL] = useState("");

  // Define constants for your S3 bucket and region
  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
  const REGION = process.env.REACT_APP_AWS_REGION;
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

  const s3Client = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
    },
  });

  // const PassedData = [
  //   {
  //     question:
  //       "What was the purpose of the Constitution (115th Amendment) Bill in relation to the Goods and Services Tax in India?",
  //     answer:
  //       "The purpose of the Constitution (115th Amendment) Bill in relation to the Goods and Services Tax in India was to operationalize the GST and enable both the Central Government and State Governments to make laws for levying the GST. This Bill aimed to provide the necessary legal framework for the implementation of GST in India by granting the power to levy tax on the 'supply of goods and services' to both the Central and State Governments.",
  //     contexts: [
  //       "Governments and taking into account their recommendations, the EC released the First Discussion paper on Goods and Services Tax in India on November 10, 2009 to obtain the inputs of industry, trade bodies, and people at large. on 22nd March 2011, the Constitution (115th Amendment) Bill was introduced in the Lok Sabha to operationalize the GST and enable Centre and States to make laws for levying of GST. However, the Bill lapsed with the dissolution of the 15th Lok Sabha. There-after, on 19th December, 2014 the Constitution (122nd Amendment) Bill, 2014 was introduced in the Lok Sabha to address various issues related to GST. It is noteworthy that the introduction of GST required a Constitutional amendment as the Constitution did not vest express power either in the Central Government or State Government to levy tax on the 'supply of goods and services'. While the Centre was empowered to tax services and goods up to the production stage, the States had the power to tax sale of goods.",
  //     ],
  //     ground_truth:
  //       "The purpose of the Constitution (115th Amendment) Bill in relation to the Goods and Services Tax in India was to operationalize the GST and enable the Centre and States to make laws for levying the GST.",
  //     faithfulness: 0.75,
  //     context_relevancy: 0.0416666667,
  //     answer_relevancy: 1.0,
  //     answer_correctness: 0.847443812,
  //     answer_similarity: 0.9897896303,
  //     context_precision: 1.0,
  //     context_recall: 1.0,
  //   },
  //   {
  //     question:
  //       "What are the issues with GST administration complexity and how does the Composition scheme address compliance burden for smaller taxpayers?",
  //     answer:
  //       "The main issue with GST administration complexity is the lack of preparedness among many people engaged in business. This lack of understanding of the provisions of GST has made it challenging for businesses to assess the mandated compliance requirements for their relevant functional departments, such as the IT Department and Legal department.\n\nTo address the compliance burden for smaller taxpayers, the Composition scheme has been implemented. This scheme subjects small businesses, manufacturers, and restaurants to a lower GST rate based on their turnover - 0.5%, 1%, and 2.5% respectively. The Composition scheme aims to simplify the compliance process for smaller taxpayers, reducing their administrative burden and making it easier for them to comply with GST regulations. This is expected to make it easier for smaller businesses to navigate the complexities of GST administration and ensure they are compliant with the regulations.",
  //     contexts: [
  //       "the scheme, small businesses, manufacturers and restaurants will be subject to a GST rate of 0.5%, 1% and 2.5% respectively on turnover. The Composition scheme has been designed to simplify and reduce the burden of compliance for smaller taxpayers. 14. benefits to consumers: The final price of goods is expected to be lower due to seamless flow of input tax credit between the manufacturer, retailer and supplier of services. Average tax burden on companies is likely to come down which is expected to reduce prices and hence benefit the consumer. Concerns regarding GST lack of preparedness: The understanding of the provisions of GST is still at a nascent stage for many people engaged in business. They are still trying to assess the mandated GST compliance provisions that their relevant functional departments (such as IT Department, Legal department) need to adhere to.",
  //     ],
  //     ground_truth:
  //       "The multiple tax structure of GST can increase administrative complexity and create classification disputes. It also makes it difficult to evaluate the overall effects of the tax design. However, the Composition scheme has been designed to simplify and reduce the burden of compliance for smaller taxpayers.",
  //     faithfulness: 1.0,
  //     context_relevancy: 0.0208333333,
  //     answer_relevancy: 0.9230523692,
  //     answer_correctness: 0.4634697782,
  //     answer_similarity: 0.9308046733,
  //     context_precision: 1.0,
  //     context_recall: 0.6666666667,
  //   },
  //   {
  //     question:
  //       "What are the issues with GST administration complexity and how does the Composition scheme address compliance burden for smaller taxpayers?",
  //     answer:
  //       "The main issue with GST administration complexity is the lack of preparedness among many people engaged in business. This lack of understanding of the provisions of GST has made it challenging for businesses to assess the mandated compliance requirements for their relevant functional departments, such as the IT Department and Legal department.\n\nTo address the compliance burden for smaller taxpayers, the Composition scheme has been implemented. This scheme subjects small businesses, manufacturers, and restaurants to a lower GST rate based on their turnover - 0.5%, 1%, and 2.5% respectively. The Composition scheme aims to simplify the compliance process for smaller taxpayers, reducing their administrative burden and making it easier for them to comply with GST regulations. This is expected to make it easier for smaller businesses to navigate the complexities of GST administration and ensure they are compliant with the regulations.",
  //     contexts: [
  //       "the scheme, small businesses, manufacturers and restaurants will be subject to a GST rate of 0.5%, 1% and 2.5% respectively on turnover. The Composition scheme has been designed to simplify and reduce the burden of compliance for smaller taxpayers. 14. benefits to consumers: The final price of goods is expected to be lower due to seamless flow of input tax credit between the manufacturer, retailer and supplier of services. Average tax burden on companies is likely to come down which is expected to reduce prices and hence benefit the consumer. Concerns regarding GST lack of preparedness: The understanding of the provisions of GST is still at a nascent stage for many people engaged in business. They are still trying to assess the mandated GST compliance provisions that their relevant functional departments (such as IT Department, Legal department) need to adhere to.",
  //     ],
  //     ground_truth:
  //       "The multiple tax structure of GST can increase administrative complexity and create classification disputes. It also makes it difficult to evaluate the overall effects of the tax design. However, the Composition scheme has been designed to simplify and reduce the burden of compliance for smaller taxpayers.",
  //     faithfulness: 1.0,
  //     context_relevancy: 0.0208333333,
  //     answer_relevancy: 0.9230523692,
  //     answer_correctness: 0.4634697782,
  //     answer_similarity: 0.9308046733,
  //     context_precision: 1.0,
  //     context_recall: 0.6666666667,
  //   },
  // ];

  const fetchDataSize = async () => {
    setLoadingEvaluationData(true);
    try {
      const response = await axios.get(
        "https://llm.aiensured.com/api/evaluate?test_size=2"
      ); // Replace with your actual API endpoint
      const parsedData = JSON.parse(response.data); // Parse the JSON string into an object
      setEvaluationData(parsedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message || "Unknown error");
    } finally {
      setLoadingEvaluationData(false);
    }
  };

  useEffect(() => {
    fetchDataSize();
  }, []);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    color: theme.palette.common.white, // Set text color to white
    "&:nth-of-type(odd)": {
      backgroundColor: "#2f2f2f", // Set odd rows to dark grey
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#3b3939", // Set even rows to a lighter dark grey
    },
    "& td, & th": {
      color: theme.palette.common.white, // Ensure text color is white for all cells
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme, width }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#a03c64", //theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 16,
      width: width || "auto", // Use passed width or default
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      width: width || "auto", // Use passed width or default
    },
  }));

  // Call fetchData in useEffect or a similar lifecycle method
  useEffect(() => {
    fetchDataSize();
  }, []);
  // useEffect(() => {
  //   if (!currentUser) {
  //     navigate("/login"); // Redirect to login if not logged in
  //   }
  // }, [currentUser, navigate]);

  useEffect(() => {
    const savedChatHistory =
      JSON.parse(localStorage.getItem("chatHistory")) || [];
    setChatHistory(savedChatHistory);
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      const { scrollHeight, clientHeight } = chatBoxRef.current;
      setShowScrollButton(scrollHeight > clientHeight);
    }
  }, [outputText, loading]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatBoxRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = chatBoxRef.current;
        setShowScrollButton(scrollTop + clientHeight < scrollHeight - 10);
      }
    };

    const chatBox = chatBoxRef.current;
    if (chatBox) {
      chatBox.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatBox) {
        chatBox.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const uploadFile = async (e) => {
    const file = e.target.files[0]; // Get the file from the input event

    if (!file) {
      alert("Please select a file to upload.");
      return;
    }
    const uuid = uuidv4();
    // Create a new File object with the same content but renamed to 'dataset.pdf'
    const renamedFile = new File([file], "gst.pdf", { type: file.type });

    // Set state with the renamed file
    setFile(renamedFile);
    setFilename("gst.pdf"); // Set filename as 'dataset.pdf'
    const destPath = `${uuid}/gst.pdf`;

    const params = {
      Bucket: S3_BUCKET,
      Key: "gst.pdf", // Fixed file name for S3
      Body: renamedFile, // Use the renamed file
      ContentType: renamedFile.type,
    };

    try {
      const data = await s3Client.send(new PutObjectCommand(params));
      console.log("Upload Success", data);

      // Construct the S3 object URL with the renamed file name 'dataset.pdf'
      const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/gst.pdf`;

      setObjectURL(url);
      console.log("Object URL:", url);

      alert("File uploaded successfully!");

      // Send the S3 URL to the backend
      await axios.post(
        "https://llm.aiensured.com/api/download",
        {
          s3_url: url,
          filename: file.name,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("S3 URL sent to backend successfully!");
    } catch (err) {
      console.error("Error", err);
      alert("File upload failed.");
    }
  };

  const removeFile = () => {
    setFile(null);
    setFilename("");
  };

  // const handleSend = async () => {
  //   setLoading(true);
  //   setShowScrollButton(false);
  //   try {
  //     const response = await generateText(inputText);
  //     setOutputText(response.output);

  //     const newChat = {
  //       input: inputText,
  //       output: response.output,
  //       score: response.Evalution_scores,
  //     };
  //     const updatedChatHistory = [...chatHistory, newChat];
  //     setChatHistory(updatedChatHistory);
  //     localStorage.setItem("chatHistory", JSON.stringify(updatedChatHistory));
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  //   setLoading(false);
  // };

  const handleSend = async () => {
    if (!inputText.trim()) return; // prevent sending empty input

    // Immediately add the input to the chat with a placeholder output
    const newChat = {
      input: inputText,
      output: null, // placeholder while awaiting the response
      score: null, // placeholder while awaiting the response
    };
    const updatedChatHistory = [...chatHistory, newChat];
    setChatHistory(updatedChatHistory);

    setInputText(""); // Clear input field after submission
    setLoading(true); // Show loading spinner
    setShowScrollButton(false); // Hide scroll button during loading

    try {
      const response = await generateText(inputText);

      // Update the last chat message with output and evaluation score
      const lastChatIndex = updatedChatHistory.length - 1;
      updatedChatHistory[lastChatIndex].output = response.output;
      updatedChatHistory[lastChatIndex].score = response.Evalution_scores;

      setChatHistory([...updatedChatHistory]);
      localStorage.setItem("chatHistory", JSON.stringify(updatedChatHistory));
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Hide loading spinner
      setShowScrollButton(true); // Show scroll button after output is displayed
    }
  };

  // const scrollToBottom = () => {
  //   window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
  // };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      e.preventDefault();
      handleSend();
      setInputText("");
    }
  };

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    if (evaluationType === "Over All") {
      fetchDataSize();
    }
  }, [evaluationType]);

  const toggleContent = (index, field) => {
    setExpandedContent((prevExpandedContent) => ({
      ...prevExpandedContent,
      [index]: {
        ...prevExpandedContent[index],
        [field]: !prevExpandedContent[index]?.[field],
      },
    }));
  };

  const handleOpenModal = (content, columnName) => {
    console.log(
      "Opening modal with content:",
      content,
      "and column name:",
      columnName
    ); // Debug log

    setModalContent(content);
    setModalTitle(columnName); // Set the column name
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalContent("");
    setModalTitle(""); // Clear the column name
  };

  const renderContent = (content, index, field, columnName) => {
    console.log("Rendering content with column name:", columnName); // Debug log
    const isExpanded = expandedContent[index]?.[field];
    if (content.length > 100) {
      return (
        <>
          {isExpanded ? content : `${content.substring(0, 150)}...`}
          <button
            className="readMore"
            onClick={() =>
              isExpanded
                ? toggleContent(index, field)
                : handleOpenModal(content, columnName)
            } // Ensure columnName is passed
          >
            {isExpanded ? "Read Less" : "Read More"}
          </button>
        </>
      );
    }
    return content;
  };

  return (
    <main className="content">
      <div className="user-info">{/* <User /> */}</div>

      <div className="rightSide">
        {evaluationType === "Over All" ? (
          <div className="evaluation-table">
            {loadingEvaluationData ? (
              <div className="loading-container">
                <ClipLoader
                  color={"blue"}
                  loading={loadingEvaluationData}
                  size={50}
                />
              </div>
            ) : (
              <TableContainer
                className="tableOfEvaluation"
                component={Paper}
                sx={{
                  // borderRadius: "1.5rem",
                  borderColor: "white",
                  backgroundColor: "#3b3939",
                  color: "white",
                  textAlign: "justify",
                  textJustify: "inter-word",
                  textAlign: "start",
                  height: "80vh",
                }}
              >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Sr.No</StyledTableCell>
                      <StyledTableCell>Question</StyledTableCell>
                      <StyledTableCell width="300px">Answer</StyledTableCell>
                      <StyledTableCell>Contexts</StyledTableCell>
                      <StyledTableCell>Ground Truth</StyledTableCell>
                      <StyledTableCell>Faithfulness</StyledTableCell>
                      <StyledTableCell>Context Relevancy</StyledTableCell>
                      <StyledTableCell>Answer Relevancy</StyledTableCell>
                      <StyledTableCell>Answer Correctness</StyledTableCell>
                      <StyledTableCell>Answer Similarity</StyledTableCell>
                      <StyledTableCell>Context Precision</StyledTableCell>
                      <StyledTableCell>Context Recall</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(evaluationData) &&
                    evaluationData.length > 0 ? (
                      evaluationData.map((data, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell>
                            {renderContent(
                              data.question,
                              index,
                              "question",
                              "Question"
                            )}{" "}
                            {/* Pass the column name */}
                          </StyledTableCell>
                          <StyledTableCell>
                            {renderContent(
                              data.answer,
                              index,
                              "answer",
                              "Answer"
                            )}{" "}
                            {/* Pass the column name */}
                          </StyledTableCell>
                          <StyledTableCell>
                            {renderContent(
                              data.contexts ? data.contexts.join(", ") : "N/A",
                              index,
                              "contexts",
                              "Contexts"
                            )}{" "}
                            {/* Pass the column name */}
                          </StyledTableCell>
                          <StyledTableCell>
                            {renderContent(
                              data.ground_truth,
                              index,
                              "ground_truth",
                              "Ground Truth"
                            )}{" "}
                            {/* Pass the column name */}
                          </StyledTableCell>
                          <StyledTableCell>{data.faithfulness}</StyledTableCell>
                          <StyledTableCell>
                            {data.context_relevancy}
                          </StyledTableCell>
                          <StyledTableCell>
                            {data.answer_relevancy}
                          </StyledTableCell>
                          <StyledTableCell>
                            {data.answer_correctness}
                          </StyledTableCell>
                          <StyledTableCell>
                            {data.answer_similarity}
                          </StyledTableCell>
                          <StyledTableCell>
                            {data.context_precision}
                          </StyledTableCell>
                          <StyledTableCell>
                            {data.context_recall}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={12} align="center">
                          No data available
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        ) : (
          <div className="chat-box-wrapper" ref={chatBoxRef}>
            <div className="output">
              {chatHistory.map((chat, index) => (
                <div key={index} className="chat-message">
                  <p className="lastinput">{chat.input}</p>
                  <div className="response-container">
                    {/* <p className="response">{chat.output || "Awaiting response..."}</p> */}

                    <p className="response">{chat.output}</p>
                    {/* <div className="scoreTable">
                      <TableContainer
                        component={Paper}
                        style={{
                          borderRadius: "1.2rem",
                          backgroundColor: "#3b3939",
                        }}
                      >
                        <Table sx={{ minWidth: 300 }} aria-label="score table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ color: "white" }}>
                                Faithfulness
                              </TableCell>
                              <TableCell
                                style={{ color: "white" }}
                                align="center"
                              >
                                Context Relevancy
                              </TableCell>
                              <TableCell
                                style={{ color: "white" }}
                                align="center"
                              >
                                Answer Relevancy
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {chat.score && (
                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{ color: "white" }}
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  {parseFloat(
                                    chat.score.faithfulness.toFixed(3)
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{ color: "white" }}
                                  align="center"
                                >
                                  {parseFloat(
                                    chat.score.context_relevancy.toFixed(3)
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{ color: "white" }}
                                  align="center"
                                >
                                  {parseFloat(
                                    chat.score.answer_relevancy.toFixed(3)
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                    </div> */}
                    {chat.score && (
                      <div className="scoreTable">
                        <TableContainer
                          component={Paper}
                          style={{
                            borderRadius: "1.2rem",
                            backgroundColor: "#3b3939",
                          }}
                        >
                          <Table
                            sx={{ minWidth: 300 }}
                            aria-label="score table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ color: "white" }}>
                                  Faithfulness
                                </TableCell>
                                <TableCell
                                  style={{ color: "white" }}
                                  align="center"
                                >
                                  Context Relevancy
                                </TableCell>
                                <TableCell
                                  style={{ color: "white" }}
                                  align="center"
                                >
                                  Answer Relevancy
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{ color: "white" }}
                                  align="center"
                                >
                                  {parseFloat(
                                    chat.score.faithfulness.toFixed(3)
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{ color: "white" }}
                                  align="center"
                                >
                                  {parseFloat(
                                    chat.score.context_relevancy.toFixed(3)
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{ color: "white" }}
                                  align="center"
                                >
                                  {parseFloat(
                                    chat.score.answer_relevancy.toFixed(3)
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              {/* Loading Spinner */}
              {loading && (
                <div className="loading-container">
                  <ClipLoader
                    color={"#a03c64"}
                    loading={loading}
                    size={100}
                    cssOverride={{
                      borderWidth: "10px",
                    }}
                  />
                </div>
              )}
              {showScrollButton && (
                <button onClick={scrollToBottom} className="scroll-to-bottom">
                  <FaArrowCircleDown className="scrolllogo" />
                </button>
              )}
            </div>
          </div>
        )}

        {evaluationType !== "Over All" && (
          <div className="inputmodel">
            <div className="fixed-input-area">
              {/* {showFileInput && (
                <>
                  <AttachFileIcon
                    
                    className="attach-icon"
                    onClick={() => document.getElementById("fileInput").click()}
                    style={{ cursor: "pointer" }}
                  />
                  <input
                    id="fileInput"
                    type="file"
                    accept=".pdf"
                    hidden
                    onChange={uploadFile} // Combined handler for selection and upload
                  />
                </>
              )} */}
              <input
                type="text"
                className="chat-input"
                placeholder="Type here to chat."
                value={inputText}
                onChange={(e) => {
                  if (e.target.value.length <= 1024) {
                    setInputText(e.target.value);
                  }
                }}
                maxLength={1024}
              />
              <button
                type="button"
                onKeyPress={handleKeyPress}
                className="send-button"
                onClick={handleSend}
              >
                &#x27A4;
              </button>
            </div>
          </div>
        )}
      </div>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "40%",
            left: "55%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "200vh",
            overflowY: "auto",
            backgroundColor: "#e6e3e3",
            color: "black",
            borderRadius: "1rem",
            textAlign: "justify",
            textJustify: "inter-word",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              fontWeight: "bold",
              fontSize: "4.5rem", // Increase font size for a larger icon
              color: "black", // Set color to black for a darker appearance
              "&:hover": {
                color: "gray", // Optional: change color on hover
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h6"
            component="h1"
            sx={{ fontWeight: "bold", ml: 1 }}
          >
            {modalTitle}
          </Typography>
          <Typography
            sx={{ mt: 2, backgroundColor: "white", borderRadius: "1rem", p: 4 }}
          >
            {modalContent}
          </Typography>
        </Box>
      </Modal>
    </main>
  );
};

export default RightSide;
