import axios from 'axios';
// const URL = 'http://127.0.0.1:8000';
// const URL = 'http://98.70.78.119:8000';
const URL = "https://llm.aiensured.com/api"
// Function to generate text
export const generateText = async (text) => {
    try {
        const response = await axios.get(`${URL}/generate`, {
            params: { text }
        });
        console.log(response.data, "response data in api file");
        return response.data;
    } catch (error) {
        console.error('Error generating text:', error);
        throw error;
    }
};

// Function to evaluate data
 export const fetchData = async () => {
    try {
        const response = await axios.get(`${URL}/evaluate?test_size=2`);
        console.log("kasnkjlasafnljfn")
        // setEvaluationData(response.data);
        console.log('Response:', response.data);
        // Handle the response data
    } catch (error) {
        console.error('Error fetching data:', error);
        // Handle the error appropriately
    }
};



